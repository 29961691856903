@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);


body {
  margin: 0;
 
    font-family: 'Roboto', sans-serif;
}

nav a{
  padding: 8px;
 text-decoration: none;
 
}

#Body{
  padding-left: 10px;
  padding-right: 10px;
}

.hidden { display:none; }